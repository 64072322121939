import { useState } from "react";
import styled from "styled-components";
import { mq } from "../../theme/breakpoints";
import { isEmail, isPhone } from "../../assets/utils/regex";

const MailSection = styled.section`
  padding: 80px 0;
  background-color: #3c75f1;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 35px;
  line-height: 1.4em;
  word-wrap: break-word;
  word-break: keep-all;
  text-align: center;
  color: #fff;
  ${mq[0]} {
    font-size: 48px;
  }
`;

const FormContainer = styled.div`
  padding: 27px;
  max-width: 534px;
  margin: 53px auto 0 auto;
  background-color: #ffffff;
  border: 1px solid #eef0f1;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
`;

const InputGroup = styled.div`
  margin-bottom: 16px;
  label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 13px;
    color: #414448;
  }
  input,
  textarea {
    display: inline-block;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e7e8e9;
    border-radius: 8px;
  }
  textarea {
    width: 100%;
    min-height: 262px;
    resize: none;
  }
`;

const SubmitButton = styled.button`
  padding: 17px 32px;
  margin-top: 16px;
  width: 100%;
  box-shadow: 0px 7.4px 17.76px rgba(0, 0, 0, 0.08);
  border-radius: 13.32px;
  border: 0;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  background: #1155e9;
  cursor: pointer;
  transition: 0.2s;
  ${mq[0]} {
    font-size: 22px;
    &:hover {
      background-color: rgb(13, 78, 191);
    }
  }
`;

const ErrMessage = styled.p`
  margin-top: 6px;
  font-size: 14px;
  color: #1155e9;
`;

const SuccessModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 300px;
    height: 250px;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid #1155e9;
  }

  p {
    font-size: 20px;
    line-height: 1.4em;
    text-align: center;
  }

  button {
    padding: 17px 32px;
    margin-top: 30px;
    width: 200px;
    box-shadow: 0px 7.4px 17.76px rgba(0, 0, 0, 0.08);
    border-radius: 13.32px;
    border: 0;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    background: #1155e9;
    cursor: pointer;
    transition: 0.2s;
    ${mq[0]} {
      font-size: 22px;
      &:hover {
        background-color: rgb(13, 78, 191);
      }
    }
  }
`;

const Mail = ({ mailref }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const handleSubmit = () => {
    setCompleteModal(true);
  };

  const closeSubmitModal = () => {
    setCompleteModal(false);
  };

  return (
    <>
      {completeModal && (
        <SuccessModal onClick={closeSubmitModal}>
          <div className="modal">
            <p>
              문의가 접수되었습니다.
              <br />
              확인 후 답변드리겠습니다.
            </p>
            <button onClick={closeSubmitModal}>확인</button>
          </div>
        </SuccessModal>
      )}

      <MailSection ref={mailref} id="adopt-section">
        <InnerWrapper>
          <Title>
            도입을 희망하시거나
            <br /> 도입관련 문의가 있으시다면
            <br /> 편하게 연락 주세요.
          </Title>
          <FormContainer>
            <form
              method="POST"
              data-email="support@pyeoda.co.kr"
              action="https://script.google.com/macros/s/AKfycby4KbzM772dRVfdOZswTvNWBIrqqHdhv0f1mmKf8kp5VNkBHhZW92Ji2zx7fODbr6wqFg/exec"
              target="frAttachFiles"
              onSubmit={handleSubmit}
            >
              <InputGroup>
                <label htmlFor="name">성함</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="성함을 입력해 주세요"
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="phone">연락처</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="연락처를 입력해 주세요"
                  required
                  value={phone}
                  onChange={(e) => {
                    setPhoneErr(!isPhone(e.target.value));
                    setPhone(e.target.value);
                  }}
                />
                {phoneErr ? (
                  <ErrMessage>*연락처를 다시 확인해 주세요.</ErrMessage>
                ) : null}
              </InputGroup>
              <InputGroup>
                <label htmlFor="email">이메일 주소</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="이메일 주소를 입력해 주세요"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmailErr(!isEmail(e.target.value));
                    setEmail(e.target.value);
                  }}
                />
                {emailErr ? (
                  <ErrMessage>*이메일을 다시 확인해 주세요.</ErrMessage>
                ) : null}
              </InputGroup>
              <InputGroup>
                <label htmlFor="message">문의내용</label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="문의내용을 입력해 주세요"
                  required
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </InputGroup>
              <SubmitButton type="submit">도입문의</SubmitButton>
            </form>
            <iframe
              title="for send mail"
              name="frAttachFiles"
              style={{ display: "none" }}
            ></iframe>
          </FormContainer>
        </InnerWrapper>
      </MailSection>
    </>
  );
};

export default Mail;
