import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const SosSection = styled.section`
  background: #3c75f1;
  overflow: hidden;
`;

const InnerWrapper = styled.div`
  position: relative;
  padding: 56px 20px;
  max-width: 1320px;
  margin: 0 auto;

  &::after {
    position: absolute;
    left: 50%;
    top: 110px;
    transform: translate(-50%, 0);
    display: block;
    content: '';
    width: 100vw;
    aspect-ratio: 1;
    background: linear-gradient(
      180deg,
      rgba(111, 178, 246, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.4;
    border-radius: 50%;

    ${mq[0]} {
      top: 160px;
    }
  }
`;

const SubTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3em;
  color: #ffffff;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;

  ${mq[0]} {
    font-size: 32px;
  }
`;

const Title = styled.p`
  position: relative;
  z-index: 2;
  margin-top: 40px;
  font-weight: 700;
  font-size: 60px;
  color: #ffffff;
  text-align: center;

  ${mq[0]} {
    font-size: 69px;
    margin-top: 50px;
  }
`;

const So = () => {
  return (
    <>
      <SosSection>
        <InnerWrapper>
          <SubTitle>
            안타깝게도 우산 쓰레기 문제를
            <br />
            저희 홀로 해결해 나가기는 어려워요..
          </SubTitle>
          <Title>그래서</Title>
        </InnerWrapper>
      </SosSection>
    </>
  );
};

export default So;
