import AdoptIntro from '../../components/Adopt/AdoptIntro';
import Esg from '../../components/Adopt/Esg';
import Suggestion from '../../components/Adopt/Suggestion';
import Benefits from '../../components/Adopt/Benefits';
import Options from '../../components/Adopt/Options';
import Mail from '../../components/Adopt/Mail';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const Adopt = () => {
  const mailSectionRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (mailSectionRef && location.hash) {
        mailSectionRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 100);
  }, [location]);

  return (
    <>
      <AdoptIntro />
      <Esg />
      <Suggestion />
      <Benefits />
      <Options />
      <Mail mailref={mailSectionRef} />
    </>
  );
};

export default Adopt;
