import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const PartnersSection = styled.section`
  padding: 80px 0;
  background: #fcfcfc;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const SubTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3em;
  text-align: center;
  color: #3c75f1;

  ${mq[0]} {
    font-size: 24px;
  }
`;

const Title = styled.h2`
  margin-top: 12px;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
  ${mq[0]} {
    margin-top: 20px;
    font-size: 84px;
  }
`;

const ContsWrapper = styled.div`
  margin-top: 76px;
`;

const ContsLabel = styled.div`
  display: inline-block;
  padding: 12px 24px;
  margin-bottom: 37px;
  background: #e7e8e9;
  border-radius: 40px;

  span {
    font-weight: 700;
    font-size: 25px;
  }
`;

const Logos = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  ${mq[0]} {
    flex-wrap: nowrap;
  }

  &.mt20 {
    margin-top: 20px;
  }
`;

const Logo = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 12px);
  aspect-ratio: 2.44;
  border: 1px solid red;
  background: #ffffff;
  border: 1px solid #eeeff0;
  border-radius: 25px;
  overflow: hidden;
  ${mq[0]} {
    width: 23.5%;
  }

  img {
    width: 60%;

    ${mq[0]} {
      width: unset;
      max-width: 174px;
      max-height: 69px;
    }
  }
`;

const Desc = styled.p`
  margin-top: 64px;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.3em;
  text-align: center;
  ${mq[0]} {
    margin-top: 94px;
    font-size: 32px;
  }
`;

const Partners = () => {
  return (
    <>
      <PartnersSection>
        <InnerWrapper>
          <SubTitle>신뢰를 바탕으로..</SubTitle>
          <Title>펴다는 지금,</Title>
          <ContsWrapper>
            <ContsLabel>
              <span>고객사</span>
            </ContsLabel>
            <Logos>
              <Logo>
                <img src="/images/partners/logo1.png" alt="삼육보건대학교" />
              </Logo>
              <Logo>
                <img src="/images/partners/logo2.png" alt="서울특별시" />
              </Logo>
              <Logo>
                <img src="/images/partners/logo3.png" alt="안산시" />
              </Logo>
              <Logo>
                <img src="/images/partners/logo4.png" alt="제주시" />
              </Logo>
            </Logos>
          </ContsWrapper>
          <ContsWrapper>
            <ContsLabel>
              <span>지원기관</span>
            </ContsLabel>
            <Logos>
              <Logo>
                <img src="/images/partners/logo5.png" alt="신용보증기금" />
              </Logo>
              <Logo>
                <img src="/images/partners/logo6.png" alt="y and archer" />
              </Logo>
              <Logo>
                <img src="/images/partners/logo7.png" alt="청년창업사관학교" />
              </Logo>
              <Logo>
                <img src="/images/partners/logo8.png" alt="Finup Partners" />
              </Logo>
            </Logos>
            <Logos className="mt20">
              <Logo>
                <img src="/images/partners/logo9.png" alt="esg실천위원회" />
              </Logo>
              <Logo>
                <img
                  src="/images/partners/logo10.png"
                  alt="한국청년기업가정신재단"
                />
              </Logo>
              <Logo>
                <img
                  src="/images/partners/logo11.png"
                  alt="서울창조경제혁신센터"
                />
              </Logo>
              <Logo>
                <img src="/images/partners/logo12.png" alt="Finup Partners" />
              </Logo>
            </Logos>
          </ContsWrapper>
          <Desc>과 함께 우산 걱정없는 세상을 만들어가고 있습니다.</Desc>
        </InnerWrapper>
      </PartnersSection>
    </>
  );
};

export default Partners;
