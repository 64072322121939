import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';
import { Link } from 'react-router-dom';
import { ReactComponent as GooglePlayStoreLogo } from '../../assets/svgs/google-play-logo.svg';
import { ReactComponent as AppStoreLogo } from '../../assets/svgs/apple-logo.svg';

const IntroSection = styled.section`
  background: url('../images/main-intro-bg.jpg') center / cover;
`;

const InnerWrapper = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  padding: 100px 20px;

  ${mq[0]} {
    display: flex;
    justify-content: space-between;
    padding: 38px 60px 70px 60px;
  }
`;

const ContsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImgArea = styled.div`
  position: relative;
  display: none;
  width: 40%;
  transform: translate(10%, -4%);
  ${mq[0]} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    position: relative;
    z-index: 2;
    width: 40vw;
    max-width: 600px;
  }
`;

const CenterGlow = styled.div`
  position: absolute;
  width: 20vw;
  max-width: 318.52px;
  aspect-ratio: 1.75;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
  z-index: 1;

  background: linear-gradient(
    288.5deg,
    rgba(217, 217, 217, 0) 6.78%,
    rgba(252, 252, 252, 0.914497) 40.84%,
    #ffffff 61.75%,
    rgba(217, 217, 217, 0) 100.19%
  );
  mix-blend-mode: luminosity;
  opacity: 0.78;
  filter: blur(25.5px);
  border-radius: 120.63px;
`;

const TextGroup = styled.div`
  word-break: keep-all;
  word-wrap: break-word;
  p {
    font-weight: 700;
    font-size: 20px;
    color: #b3b6bb;

    ${mq[0]} {
      font-size: 28px;
    }
  }

  h2 {
    margin-top: 10px;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.2em;
    color: #ffffff;
    word-wrap: break-word;
    word-break: keep-all;
    ${mq[0]} {
      margin-top: 8px;
      font-size: 64px;
    }
  }
`;

const ButtonsGroup = styled.div`
  margin-top: 27px;
  display: flex;
  gap: 10px;
`;

const SocialButton = styled.p`
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 11px 14px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 9.6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  transition: 0.2s;

  ${mq[0]} {
    font-size: 16px;
    padding: 17px 20px;
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  svg {
    width: 14px;
    margin-right: 5px;
    ${mq[0]} {
      width: 16px;
      margin-right: 7px;
    }
  }
`;

const AdoptButton = styled.div`
  a {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 536px;
    padding: 20px 25px;
    margin-top: 50px;
    background-color: #1155e9;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.2s;

    ${mq[0]} {
      padding: 10px 32px;
      &:hover {
        background-color: #0d4ebf;
      }
    }

    img {
      width: 30vw;
      max-width: 85px;
      transform: translateY(-5px);
      ${mq[0]} {
        width: 100px;
        display: block;
      }
    }
  }
`;

const AdoptButtonTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  padding-bottom: 10px;
  word-break: keep-all;
  word-wrap: break-word;
  ${mq[0]} {
    margin-left: 29px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    opacity: 0.6;
    ${mq[0]} {
      font-size: 18px;
    }
  }
  h3 {
    margin-top: 10px;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    ${mq[0]} {
      font-size: 28px;
    }
  }
`;

const AdoptButtonArrow = styled.div`
  position: absolute;
  bottom: 20px;
  right: 15px;
  width: 50px;
  aspect-ratio: 7.49;
  background: url('/images/button-arrow.png') center / cover;

  ${mq[0]} {
    display: block;
    top: 50%;
    bottom: unset;
    right: 32px;
    width: 80px;
    transform: translateY(-50%);
  }
`;

const EcoVadisText = styled.div`
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 20px;
  max-width: 536px;
  padding: 10px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  word-wrap: break-word;
  word-break: keep-all;

  ${mq[0]} {
    font-size: 25px;
    padding: 10px;
    border-radius: 12px;
  }

  img {
    height: 20px;
    margin-right: 10px;
    ${mq[0]} {
      height: 24px;
    }
  }
`;

const MainIntro = () => {
  return (
    <IntroSection>
      <InnerWrapper>
        <ContsArea>
          <EcoVadisText>
            <h2>
              <img src="/images/ecovadis-logo.png" alt="에코바디스" />
              에코바디스 지속 가능성 평가 및 컨설팅
            </h2>
          </EcoVadisText>
          <TextGroup>
            <p>갑자기 오는 비</p>
            <h2>우산, 사지말고 빌리세요.</h2>
          </TextGroup>
          <ButtonsGroup>
            <SocialButton>
              <GooglePlayStoreLogo />
              Google Play
            </SocialButton>
            <SocialButton>
              <AppStoreLogo />
              App Store
            </SocialButton>
          </ButtonsGroup>
          <AdoptButton>
            <Link to="/adopt">
              <div>
                <img src="/images/pyeoda-box.png" alt="펴다 박스" />
              </div>
              <AdoptButtonTextGroup>
                <p>펴다 스마트우산공유함</p>
                <h3>설치 / 도입 하기</h3>
              </AdoptButtonTextGroup>
              <AdoptButtonArrow />
            </Link>
          </AdoptButton>
        </ContsArea>
        <ImgArea>
          <img src="/images/main-intro-logo-box.png" alt="" />
          <CenterGlow />
        </ImgArea>
      </InnerWrapper>
    </IntroSection>
  );
};

export default MainIntro;
