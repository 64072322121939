import styled from 'styled-components';
import MainIntro from '../../components/Main/MainIntro';
import Questions from '../../components/Main/Questions';
import Effect from '../../components/Main/Effect';
import Steps from '../../components/Main/Steps';
import Together from '../../components/Main/Together';
import Video from '../../components/Main/Video';
import Partners from '../../components/Main/Partners';
import So from '../../components/Main/So';
import Outro from '../../components/Main/Outro';

const PageContainer = styled.div`
  min-height: 100vh;
`;

const Main = () => {
  return (
    <>
      <PageContainer>
        <MainIntro />
        <Questions />
        <Effect />
        <Steps />
        <Together />
        <Video />
        <Partners />
        <So />
        <Outro />
      </PageContainer>
    </>
  );
};

export default Main;
