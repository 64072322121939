import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';
import ReactPlayer from 'react-player/lazy';

const VideoSection = styled.section`
  padding: 80px 0;
  background: #fcfcfc;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 0 auto;
`;

const VideoWrapper = styled.div`
  max-width: 1096px;
  video {
    width: 100%;
    margin-top: 75px;

    border-radius: 20px;
    ${mq[0]} {
      border-radius: 40px;
    }
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;

  ${mq[0]} {
    font-size: 64px;
  }
`;

const Video = () => {
  return (
    <>
      <VideoSection>
        <InnerWrapper>
          <Title>펴다가 더 궁금하신가요?</Title>
          <VideoWrapper>
            <ReactPlayer
              url={'https://static.pyeoda.co.kr/video/pyeoda-intro.mp4 '}
              width={'100%'}
              height={'auto'}
              playing={true}
              muted={true}
              controls={true}
              pip={true}
              loop={true}
              playsinline={true}
            />
          </VideoWrapper>
        </InnerWrapper>
      </VideoSection>
    </>
  );
};

export default Video;
