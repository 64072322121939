import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const IntroSection = styled.section`
  position: relative;
  background-color: #3c75f1;
  overflow: hidden;

  &::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    content: '';
    min-height: 100%;
    height: 40vw;
    aspect-ratio: 0.81;
    background: url('/images/adopt-intro-bg.png') center / cover;
  }
`;

const InnerWrapper = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  padding: 100px 20px;
`;

const SubTitle = styled.p`
  position: relative;
  z-index: 2;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3em;
  color: #ffffff;
  opacity: 0.5;
  word-wrap: break-word;
  word-break: keep-all;

  ${mq[0]} {
    font-size: 24px;
  }
`;

const So = styled.p`
  position: relative;
  z-index: 2;
  margin-top: 13px;
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
  word-wrap: break-word;
  word-break: keep-all;

  ${mq[0]} {
    margin-top: 18px;
    font-size: 40px;
  }
`;

const Title = styled.h2`
  position: relative;
  z-index: 2;
  margin-top: 10px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3em;
  color: #ffffff;
  word-wrap: break-word;
  word-break: keep-all;
  ${mq[0]} {
    margin-top: 18px;
    font-size: 48px;
  }

  span {
    white-space: nowrap;
    display: inline-block;
    margin: 5px 0;
    padding: 4px 20px;
    background: #e7eefd;
    border-radius: 40px;
    color: #1155e9;
    ${mq[0]} {
      padding: 5px 24px;
    }
  }

  strong {
    color: rgb(55, 213, 108);
  }
`;

const AdoptButton = styled.p`
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-top: 50px;
  padding: 18px 50px;
  background-color: #ffffff;
  border: 1.48px solid #9bb8f8;
  box-shadow: 0px 7.4px 17.76px rgba(0, 0, 0, 0.08);
  border-radius: 13.32px;
  cursor: pointer;
  ${mq[0]} {
    margin-top: 65px;
    font-size: 23px;
  }
  span {
    font-weight: 700;
    font-size: 20px;
    color: #0d44ba;
    ${mq[0]} {
      font-size: 23px;
    }
  }
`;

const AdoptIntro = () => {
  const moveToAdoptSection = () => {
    const scrollPosition = document.querySelector('#adopt-section').offsetTop;
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  };

  return (
    <>
      <IntroSection>
        <InnerWrapper>
          <SubTitle>
            안타깝게도 우산 쓰레기 문제를
            <br />
            저희 홀로 해결해 나가기는 어려워요..
          </SubTitle>
          <So>그래서</So>
          <Title>
            저희와 함께 우산문제를 해결하고
            <br />
            <strong>ESG</strong> 평가 점수까지 얻을 수 있는
            <br />
            <span>업체</span> 혹은 <span>지자체</span>
            <br />를 구하고있어요
          </Title>
          <AdoptButton onClick={moveToAdoptSection}>
            <span>도입문의</span>
          </AdoptButton>
        </InnerWrapper>
      </IntroSection>
    </>
  );
};

export default AdoptIntro;
