import styled from 'styled-components'

const ContentSection = styled.section`
  padding: 0 20px;
`

const ReductionCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px 14px 16px 24px;
  margin: 21px auto 0;
  background: #FFFFFF;
  border: 1px solid #ECEFF2;
  box-shadow: 0 3px 5px rgba(38, 40, 43, 0.04);
  border-radius: 10px;

  img {
    width: 36px;
    height: 45px;
  }
`

const ReductionCardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 4px;
  width: 230px;
  height: 42px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #72787F;
  }

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
  }
`

const ReductionIntro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    word-break: keep-all;
    color: #333333;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: #333333;
    }
  }

  span > span {
    color: #1155E9;
  }
`

const ReductionMeaningTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 32px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    align-self: flex-start;
    color: #000000;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #72787F;
  }
`

const ReductionMeaningContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 31px 13px 45px;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #72787F;
  }
`

const Tree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  width: 274px;

  img {
    width: 133px;
    height: 166px;
  }
`

const Highway = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 53px;
  width: 274px;
  margin-top: 65px;

  img {
    width: 244px;
    height: 172px;
  }

  div {
    display: flex;
    gap: 7px;
    flex-direction: column;
    width: 274px;
    align-items: center;
  }
`

const Computer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
  width: 274px;
  margin-top: 45px;

  img {
    width: 174px;
    height: 191px;
  }
`

const ReductionContent = ({ reductionValue }) => {
  return <ContentSection>
    <ReductionCard>
      <img src="/images/reduction/tree.png" alt="탄소 저감" />
      <ReductionCardText>
        <p>
          우리의 총 탄소 저감량
        </p>
        <span>{reductionValue.toLocaleString()}t</span>
      </ReductionCardText>
    </ReductionCard>
    <ReductionIntro>
      <h5>탄소 저감이란?</h5>
      <p>
        우산 이용도 탄소를 배출한다는 사실 알고계셧나요? 우리는 갑작스런 비소식에 1회용 우산,비닐 우산을 애용하곤 합니다.
        <br /> <br />
        비닐우산 생산에 배출되는 탄소와 썩는데 100년이 걸리는것을 생각하면 환경에 치명적이라는것을 알 수 있는데요.
        <br /> <br />
        탄소 저감이란 기후 위기 극복을 위해 탄소 배출량을 저감을 하는 것을 말해요!
        <br /> <br />
        <span>펴다 공유 우산 10회 이용에 <span>2,800g 의 탄소 절감 효과</span>가 있답니다.</span>
      </p>
    </ReductionIntro>
    <ReductionMeaningTitle>
      <h1 className={'start'}>탄소 2,800g의 의미</h1>
      <span className={'start'}>공유 우산 10회 이용</span>
    </ReductionMeaningTitle>
    <ReductionMeaningContent>
      <Tree>
        <img src={'/images/reduction/tree.png'} alt="소나무" />
        <h2>30년생 소나무 한 그루의<br />1년 흡수량</h2>
      </Tree>
      <Highway>
        <img src={'/images/reduction/highway.png'} alt="소나무" />
        <div>
          <h2>승용차 2회 왕복 배출량</h2>
          <span>2,000cc 휘발유 승용차 기준</span>
        </div>
      </Highway>
      <Computer>
        <img src={'/images/reduction/computer.png'} alt="소나무" />
        <h2>30%의 기업 컴퓨터<br />절전기능 사용</h2>
      </Computer>
    </ReductionMeaningContent>
  </ContentSection>
}

export default ReductionContent
