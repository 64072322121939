import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import PretendardBold from '../fonts/Pretendard-Bold.ttf';
import PretendardMedium from '../fonts/Pretendard-Medium.ttf';
import PretendardRegular from '../fonts/Pretendard-Regular.ttf';

const GlobalStyle = createGlobalStyle`
	${reset}
    body{
        min-width: 280px;
        max-width: 100%;
        overflow-x: hidden;
    }
    @font-face {
        font-family: 'Pretendard';
        src: local('Pretendard');
        font-weight: 700;
        font-style: normal;
        src: url(${PretendardBold}) format('truetype');
    }

    @font-face {
        font-family: 'Pretendard';
        src: local('Pretendard');
        font-weight: 500;
        font-style: normal;
        src: url(${PretendardMedium}) format('truetype');
    }

    @font-face {
        font-family: 'Pretendard';
        src: local('Pretendard');
        font-weight: 400;
        font-style: normal;
        src: url(${PretendardRegular}) format('truetype');
    }
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    }
    a{
        text-decoration: none;
        color: #000000;
    }
`;

export default GlobalStyle;
