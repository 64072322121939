import styled from 'styled-components'

const BannerSection = styled.section`

  img {
    width: 100%;
    max-height: 394px;
    object-fit: cover;
  }
`


const BannerImage = () => {
  return <BannerSection>
    <img src="/images/reduction/reduction-banner.png" alt="탄소 저감" />
  </BannerSection>
}


export default BannerImage
