import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const TogetherSection = styled.section`
  overflow: hidden;
  background-color: #3c75f1;
`;

const InnerWrapper = styled.div`
  position: relative;
  padding: 80px 20px;
  max-width: 1320px;
  margin: 0 auto;

  ${mq[0]} {
    padding: 120px 20px;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: -25%;
    transform: translateY(-50%);
    display: block;
    content: '';
    width: 80vw;
    aspect-ratio: 1;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(111, 178, 246, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    ${mq[0]} {
      width: 930px;
    }
  }
`;

const SubTitle = styled.p`
  position: relative;
  z-index: 2;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4em;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;

  ${mq[0]} {
    font-size: 32px;
  }
`;

const Title = styled.h2`
  position: relative;
  z-index: 2;
  margin-top: 50px;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4em;
  text-align: center;
  color: #ffffff;

  ${mq[0]} {
    margin-top: 102px;
    font-size: 64px;
  }
`;

const Together = () => {
  return (
    <>
      <TogetherSection>
        <InnerWrapper>
          <SubTitle>
            펴다는 전국의 우산쓰레기 문제를 해결하기위해
            <br />
            지금도 뛰고있습니다.
          </SubTitle>
          <Title>
            함께 ‘펴다’ 하고
            <br />
            우산쓰레기 없는
            <br />
            사회를 만들어가요
          </Title>
        </InnerWrapper>
      </TogetherSection>
    </>
  );
};

export default Together;
