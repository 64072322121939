import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const QuestionsSection = styled.section`
  padding: 80px 0;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${mq[0]} {
    flex-direction: row;
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  aspect-ratio: 1.5;
  border-radius: 45px;

  ${mq[0]} {
    width: 50%;
    border-radius: 45px;
    aspect-ratio: 0.858;
  }
`;

const LeftCard = styled(Card)`
  color: #fff;
  font-weight: 700;
  text-align: center;
  background: url('/images/question-card-left-bg.jpg') center/cover;

  p {
    font-size: 16px;
    ${mq[0]} {
      font-size: 24px;
    }
  }
  h5 {
    font-size: 40px;
    line-height: 1.2em;
    ${mq[0]} {
      font-size: 64px;
    }
  }
`;

const RightCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  padding: 30px 20px;
  box-sizing: border-box;
  font-weight: 700;
  text-align: center;
  background: url('/images/question-card-right-bg.jpg') center/cover;
  ${mq[0]} {
    padding: 60px 20px;
  }

  p {
    font-size: 16px;
    color: #595959;
    ${mq[0]} {
      font-size: 24px;
    }
  }
  h5 {
    margin-top: 10px;
    font-size: 24px;
    color: #262626;
    line-height: 1.2em;
    ${mq[0]} {
      font-size: 34px;
    }
  }
`;

const InfoBoxGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 258px;
  transform: translateY(28px);
  ${mq[0]} {
    max-width: 500px;
  }
`;

const InfoBox = styled.div`
  display: inline-block;
  padding: 12px 15px;
  width: 200px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 7.46178px 14.9236px rgba(0, 0, 0, 0.02);
  border-radius: 13px;
  text-align: left;
  ${mq[0]} {
    width: 270px;
    padding: 20px 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
    ${mq[0]} {
      font-size: 18px;
    }
  }

  strong {
    display: block;
    margin-top: 10px;
    font-weight: 700;
    font-size: 20px;
    color: #e96364;
    ${mq[0]} {
      font-size: 34px;
    }
  }
`;

const FirstInfoBox = styled(InfoBox)`
  position: relative;
  transform: translate(-10px, 0);
  ${mq[0]} {
    transform: translate(29px, 30px);
  }
`;

const SecondInfoBox = styled(InfoBox)`
  position: relative;
  align-self: flex-end;
  transform: translate(20%, -10px);
  ${mq[0]} {
    transform: translate(0, 10px);
  }
  strong {
    color: #ee8787;
  }
`;

const ThirdInfoBox = styled(InfoBox)`
  position: relative;
  transform: translate(10%, -20px);

  ${mq[0]} {
    transform: translate(55px, -10px);
  }
`;

const Questions = () => {
  return (
    <>
      <QuestionsSection>
        <InnerWrapper>
          <Cards>
            <LeftCard>
              <p>갑자기 비가와 하나씩 사왔던 우산,</p>
              <h5>
                집에 쌓여있지
                <br />
                않나요?
              </h5>
            </LeftCard>
            <RightCard>
              <p>그 우산들이 버려져서 야기하는</p>
              <h5>
                환경오염이 상당하다는 것은
                <br />
                알고 계시나요?
              </h5>
              <InfoBoxGroup>
                <FirstInfoBox>
                  <p>한 해 버려지는 우산</p>
                  <strong>1억3천만개</strong>
                </FirstInfoBox>
                <SecondInfoBox>
                  <p>일회용 우산 쓰레기 폐기 연간</p>
                  <strong>4천억원</strong>
                </SecondInfoBox>
                <ThirdInfoBox>
                  <p>누적 탄소배출, 유해가스</p>
                  <strong>276만톤</strong>
                </ThirdInfoBox>
              </InfoBoxGroup>
            </RightCard>
          </Cards>
        </InnerWrapper>
      </QuestionsSection>
    </>
  );
};

export default Questions;
