import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const OutroSection = styled.section`
  padding: 80px 0;
  background: #ffffff;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 1.3em;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
  ${mq[0]} {
    font-size: 48px;
  }

  strong {
    color: #1155e9;
    &.green {
      color: #37d56c;
    }
  }
`;

const ImgBox = styled.div`
  margin-top: 60px;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  aspect-ratio: 1.7;
  border-radius: 20px;
  background: url('/images/outro-card-bg.jpg') center / cover;
  ${mq[0]} {
    padding: 60px 0;
  }
`;

const CardSubTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #595959;
  ${mq[0]} {
    font-size: 24px;
  }
`;

const CardTitle = styled.p`
  margin-top: 17px;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.3em;
  text-align: center;
  color: #262626;
  word-wrap: break-word;
  word-break: keep-all;
  ${mq[0]} {
    font-size: 34px;
  }
`;

const AdoptButton = styled.div`
  a {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 20px 30px;
    margin-top: 50px;
    background-color: #1155e9;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.2s;

    ${mq[0]} {
      margin-top: 112px;
      padding: 40px 50px;
      &:hover {
        background-color: #0d4ebf;
      }
    }

    img {
      position: absolute;
      top: -20px;
      width: 100px;
      transform: translateY(-5px);
      ${mq[0]} {
        top: -50px;
        width: 216px;
        display: block;
      }
    }
  }
`;

const AdoptButtonTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  padding-bottom: 10px;
  padding-left: 110px;
  word-break: keep-all;
  word-wrap: break-word;
  ${mq[0]} {
    margin-left: 29px;
    padding-left: 250px;
  }

  span {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    opacity: 0.6;
    ${mq[0]} {
      font-size: 32px;
    }
  }
  h3 {
    margin-top: 15px;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    ${mq[0]} {
      margin-top: 22px;
      font-size: 54px;
    }
  }
`;

const AdoptButtonArrow = styled.div`
  position: absolute;
  display: none;
  bottom: 66px;
  right: 57px;
  width: 15vw;
  max-width: 198px;
  aspect-ratio: 7.49;
  background: url('/images/button-arrow.png') center / cover;

  ${mq[0]} {
    display: block;
  }
`;

const Outro = () => {
  return (
    <>
      <OutroSection>
        <InnerWrapper>
          <Title>
            저희와 함께 우산문제를 해결하고
            <br />
            <strong className="green">ESG</strong> 평가 점수까지 얻을 수 있는
            <br />
            <strong>기업</strong> 혹은 <strong>지자체 </strong>를 구하고 있어요
          </Title>
          <ImgBox>
            <CardSubTitle>펴다와 함께</CardSubTitle>
            <CardTitle>
              고민없이 ESG를 실천하고 함께
              <br />
              우산 쓰레기 없는 세상을
              <br />
              만들어가 보는건 어떨까요?
            </CardTitle>
          </ImgBox>
          <AdoptButton>
            <Link to="/adopt">
              <div>
                <img src="/images/pyeoda-box.png" alt="펴다 박스" />
              </div>
              <AdoptButtonTextGroup>
                <span>펴다 스마트우산공유함</span>
                <h3>설치 / 도입 하기</h3>
              </AdoptButtonTextGroup>
              <AdoptButtonArrow />
            </Link>
          </AdoptButton>
        </InnerWrapper>
      </OutroSection>
    </>
  );
};

export default Outro;
