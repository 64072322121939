import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const EffectSection = styled.section`
  padding: 80px 0;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const SubTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3em;
  text-align: center;
  color: #3c75f1;

  ${mq[0]} {
    font-size: 24px;
  }
`;

const Title = styled.h2`
  margin-top: 12px;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  ${mq[0]} {
    margin-top: 20px;
    font-size: 84px;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 60px;
  ${mq[0]} {
    margin-top: 80px;
  }
`;

const ContsGroup = styled.div`
  width: 100%;
  ${mq[0]} {
    width: 60%;
  }
`;

const ContsBox = styled.div`
  height: unset;
  padding: 33px 41px;
  box-sizing: border-box;
  background: #f5f5f6;
  border-radius: 40px;
  ${mq[0]} {
    height: calc(50%-10px);
  }

  &:nth-child(2) {
    margin-top: 20px;
  }

  p {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.3em;
    color: #414448;
    word-wrap: break-word;
    word-break: keep-all;
    ${mq[0]} {
      font-size: 34px;
    }

    strong {
      color: #3c75f1;
    }
  }

  img {
    width: 100%;
    margin-top: 20px;
    ${mq[0]} {
      margin-top: 40px;
    }
  }
`;

const ImageBox = styled.div`
  display: none;
  width: 40%;
  border-radius: 40px;
  background: url('/images/effect-bg.jpg') center / cover;
  ${mq[0]} {
    display: block;
  }
`;

const Effect = () => {
  return (
    <>
      <EffectSection>
        <InnerWrapper>
          <SubTitle>
            우산을 공유하고
            <br />
            우산 쓰레기없는 사회를 만들어가요
          </SubTitle>
          <Title>‘펴다’ 하세요.</Title>
          <BoxWrapper>
            <ContsGroup>
              <ContsBox>
                <p>
                  우산 10번 빌리면,
                  <br />
                  소나무 한그루 심는 효과가 있어요.
                </p>
                <img src="/images/effect-infography.png" alt="" />
              </ContsBox>
              <ContsBox>
                <p>
                  시중 우산 가격 대비
                  <br />
                  <strong>6배 이상</strong> 저렴한 대여가격
                </p>
                <img src="/images/effect-graph.png" alt="" />
              </ContsBox>
            </ContsGroup>
            <ImageBox />
          </BoxWrapper>
        </InnerWrapper>
      </EffectSection>
    </>
  );
};

export default Effect;
