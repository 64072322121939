import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const BenefitsSection = styled.section`
  padding: 80px 0;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 1.4em;
  word-wrap: break-word;
  word-break: keep-all;
  text-align: center;
  ${mq[0]} {
    font-size: 40px;
  }
`;

const Cards = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 46px;
  ${mq[0]} {
    gap: 23px;
    margin-top: 116px;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  ${mq[0]} {
    width: calc(33% - 23px * 2 / 3);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 25px 20px;
  background: #f6f7fa;
  border-radius: 20px;
  ${mq[0]} {
    gap: 39px;
    height: 60%;
    padding: 29px 23px;
    border-radius: 40px;
  }

  img {
    width: 55px;
    aspect-ratio: 1;
    ${mq[0]} {
      width: 80px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;
    color: #222222;
    word-wrap: break-word;
    word-break: keep-all;
  }
`;

const CardDesc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0;
  ${mq[0]} {
    padding: 44px 0 32px;
  }
  p {
    font-weight: 500;
    font-size: 18.7068px;
    line-height: 150%;
    text-align: center;
    color: #222222;
    word-wrap: break-word;
    word-break: keep-all;
  }
`;

const Benefits = () => {
  return (
    <>
      <BenefitsSection>
        <InnerWrapper>
          <Title>
            펴다박스 도입 시 다양한
            <br /> 이점들을 누리실 수 있습니다.
          </Title>
          <Cards>
            <CardWrapper>
              <Card>
                <img src="/images/benefits/benefit1.png" alt="icon" />
                <p>
                  펴다 사용으로인한 탄소 절감 성과를 매 월 ESG 레포트 및 액셀
                  데이터로 보내드려, 의무화되는 지속가능경영보고서 발행에
                  유용하게 활용 가능합니다.
                </p>
              </Card>
              <CardDesc>
                <p>매월 ESG레포트 제공</p>
              </CardDesc>
            </CardWrapper>
            <CardWrapper>
              <Card>
                <img src="/images/benefits/benefit2.png" alt="icon" />
                <p>
                  검증된 탄소배출 감축 효과로 ESG 친환경 부문 실적을 쌓고,
                  친환경 기업으로의 브랜드 이미지를 제공합니다
                </p>
              </Card>
              <CardDesc>
                <p>
                  ESG 중 친환경 부문 <br />
                  (Environmental) 실적 창출
                </p>
              </CardDesc>
            </CardWrapper>
            <CardWrapper>
              <Card>
                <img src="/images/benefits/benefit3.png" alt="icon" />
                <p>
                  펴다는 ‘우산 수리센터/수거팀'에 취약계층을 고용하여, 펴다 박스
                  도입 시 취약계층의 일자리가 늘어나, 사회 부문에 공헌합니다
                </p>
              </Card>
              <CardDesc>
                <p>
                  ESG 중 사회 부문 <br />
                  (Social) 실적 창출
                </p>
              </CardDesc>
            </CardWrapper>
            <CardWrapper>
              <Card>
                <img src="/images/benefits/benefit4.png" alt="icon" />
                <p>
                  우산 대여비의 일부는 복지 예산과 환경 예산에 기부되어
                  지속가능한 사회를 만드는데에 공헌합니다
                </p>
              </Card>
              <CardDesc>
                <p>
                  대여비를 일부를 복지 예산 및 <br />
                  환경 예산에 기부 ESG 중 사회 부문
                </p>
              </CardDesc>
            </CardWrapper>
            <CardWrapper>
              <Card>
                <img src="/images/benefits/benefit5.png" alt="icon" />
                <p>
                  매월 고객사에게 이용자 데이터(우천 시 시민들의 이동경로 등)와
                  우산 쓰레기 절감 실적을 정리하여 보내드려, 행정/마케팅/기획에
                  다양하게 활용가능합니다.
                </p>
              </Card>
              <CardDesc>
                <p>매 월 사용자 데이터 DB 제공</p>
              </CardDesc>
            </CardWrapper>
            <CardWrapper>
              <Card>
                <img src="/images/benefits/benefit6.png" alt="icon" />
                <p>
                  시민/고객이 우산 자유롭게 우산을 대여/반납하여 불필요한 우산
                  구매를 하지 않아도 되도록 돕고, 펴다 박스가 설치된 곳으로
                  시민/고객을 유도하여 잠재적 고객을 늘릴 수 있습니다.
                </p>
              </Card>
              <CardDesc>
                <p>시민/고객 복지와 편의성 및 유인효과 증대</p>
              </CardDesc>
            </CardWrapper>
          </Cards>
        </InnerWrapper>
      </BenefitsSection>
    </>
  );
};

export default Benefits;
