import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const SuggestionSection = styled.section`
  padding: 80px 0;
  background-color: #f8f7f7;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
  ${mq[0]} {
    flex-direction: row;
    gap: 60px;
  }
`;

const GroupLeft = styled.div`
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.4em;
    word-wrap: break-word;
    word-break: keep-all;
    ${mq[0]} {
      font-size: 40px;
    }

    strong {
      color: #1155e9;
    }
  }

  div {
    margin-top: 30px;
    padding: 15px 20px;
    background: #e7eefd;
    border-radius: 20px;
    ${mq[0]} {
      margin-top: 49px;
      padding: 24px 32px;
      border-radius: 40px;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4em;
      color: #1155e9;
      word-wrap: break-word;
      word-break: keep-all;
      ${mq[0]} {
        font-size: 24px;
      }

      br {
        display: none;
        ${mq[0]} {
          display: inline-block;
        }
      }
    }
  }
`;

const GroupRight = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  ${mq[0]} {
    margin-top: 0;
  }
  img {
    width: 100%;
  }
`;

const Suggestion = () => {
  return (
    <>
      <SuggestionSection>
        <InnerWrapper>
          <GroupLeft>
            <h2>
              펴다와 함께 <strong>고민없이 ESG</strong>를 실천하고 함께 우산
              쓰레기 없는 세상을 만들어가 보는건 어떨까요?
            </h2>
            <div>
              <p>
                펴다는 검증된 우수한 탄소 절감효과로
                <br /> 대한민국 공식 ESG 가이드인
                <br /> K-ESG의 아래 기준을 충족합니다.
              </p>
            </div>
          </GroupLeft>
          <GroupRight>
            <img
              src="/images/suggestion-infography.png"
              alt="ESG 탄소 절감 효과 인증 자료"
            />
          </GroupRight>
        </InnerWrapper>
      </SuggestionSection>
    </>
  );
};

export default Suggestion;
