import { useEffect, useState } from 'react'
import BannerImage from '../../components/Reduction/BannerImage'
import ReductionContent from '../../components/Reduction/ReductionContent'

const Reduction = () => {
  const [reductionValue, setReductionValue] = useState(0)

  useEffect(() => {
    fetch('https://external-api.pyeoda.co.kr/umbrella/use/reduction/total')
      .then(res => res.json())
      .then(data => {
        setReductionValue(data.amount)
      })
  }, [])

  return (
    <>
      <BannerImage />
      <ReductionContent reductionValue={reductionValue} />
    </>
  )
}

export default Reduction
