import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { mq } from '../../theme/breakpoints';

const HeaderContainer = styled.header`
  position: fixed;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
  height: 62px;
  background: #ffffff;
  border-bottom: 1px solid #f5f5f5;

  ${mq[0]} {
    height: 82px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 100%;
  max-width: 1320px;
  margin: 0 auto;
`;

const Logo = styled.a`
  display: inline-block;
  height: 23px;
  ${mq[0]} {
    height: 32px;
  }

  img {
    height: 100%;
  }
`;

const Menus = styled.ul`
  flex: 1;
  margin-left: 20px;

  ${mq[0]} {
    margin-left: 60px;
  }
`;

const Menu = styled.li`
  font-weight: 700;
  font-size: 14px;

  ${mq[0]} {
    font-size: 16px;
  }

  a {
    color: #595959;
    transition: 0.2s;
    ${mq[0]} {
      &:hover {
        color: #1155e9;
      }
    }
  }
`;

const AdoptButton = styled.p`
  flex-shrink: 0;
  padding: 12px 18px;
  background: #1155e9;
  border-radius: 41px;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;

  ${mq[0]} {
    padding: 15px 22px;
    font-size: 16px;
    &:hover {
      background-color: #0d4ebf;
    }
  }
`;

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderContainer>
        <InnerWrapper>
          <Logo href="/">
            <img src="/images/logo.png" alt="펴다" />
          </Logo>
          <Menus>
            <Menu>
              <Link to="/">홈</Link>
            </Menu>
          </Menus>
          <AdoptButton
            onClick={() => {
              navigate('/adopt#adopt-section');
            }}
          >
            도입문의
          </AdoptButton>
        </InnerWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
