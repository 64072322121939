import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';
import { ReactComponent as EsgTextImage } from '../../assets/svgs/esg-text.svg';

const EsgSection = styled.section`
  padding: 80px 0;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const IntroTitle = styled.p`
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  line-height: 1.3em;
  word-wrap: break-word;
  word-break: keep-all;
  color: #000000;
  ${mq[0]} {
    font-size: 48px;
  }

  br {
    display: none;
    ${mq[0]} {
      display: inline-block;
    }
  }
`;

const QuestionGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  margin-top: 60px;
  ${mq[0]} {
    flex-direction: row;
    gap: 63px;
    margin-top: 75px;
  }

  svg {
    width: 50%;
  }
  div {
    word-wrap: break-word;
    word-break: keep-all;
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.3em;
      text-align: center;
      color: #757575;
      ${mq[0]} {
        font-size: 24px;
        text-align: left;
      }
    }
    h5 {
      margin-top: 17px;
      font-weight: 700;
      font-size: 30px;
      line-height: 1.3em;
      text-align: center;
      color: #3c75f1;
      ${mq[0]} {
        font-size: 48px;
        text-align: left;
      }
    }
  }
`;

const Card = styled.div`
  background: #f5f5f6;
  border-radius: 20px;
  ${mq[0]} {
    border-radius: 40px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
  margin-top: 60px;
  ${mq[0]} {
    flex-direction: row;
    margin-top: 90px;
  }
`;

const LeftCardGroup = styled.div`
  width: 100%;
  ${mq[0]} {
    width: 50%;
  }
`;

const HorizontalCard = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: calc(33.3% - 80px / 3);
  padding: 20px 32px;
  box-sizing: border-box;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 250px;
    ${mq[0]} {
      max-width: 420px;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.4em;
      word-break: keep-all;
      word-wrap: break-word;
      color: #414448;
      ${mq[0]} {
        font-size: 24px;
      }
    }
    p {
      margin-top: 9px;
      font-weight: 700;
      font-size: 15px;
      word-break: keep-all;
      word-wrap: break-word;
      color: #03102b;
      opacity: 0.5;
    }
    span {
      margin-top: 10px;
      display: block;
      font-size: 15px;
      word-break: keep-all;
      word-wrap: break-word;
      color: #03102b;
      opacity: 0.5;
    }
  }

  img {
    width: 20vw;
    max-width: 96px;
    height: 20vw;
    max-height: 96px;
    margin: 8px 0;
  }
`;

const RightCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  ${mq[0]} {
    width: 50%;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  img {
    width: 80%;
    margin-top: 18px;
    transform: translateX(4%);
  }
`;

const Esg = () => {
  return (
    <>
      <EsgSection>
        <InnerWrapper>
          <IntroTitle>
            펴다 스마트우산공유함(펴다 박스)을 설치하면
            <br /> 무엇이 좋을까요?
          </IntroTitle>
          <QuestionGroup>
            <EsgTextImage />
            <div>
              <p>다들 좋다고 하고, 해야한다고 하는데...</p>
              <h5>
                어떻게 시작할지
                <br /> 막막하지 않으신가요?
              </h5>
            </div>
          </QuestionGroup>
          <CardsContainer>
            <LeftCardGroup>
              <HorizontalCard>
                <div>
                  <h3>앞으로 ESG 성과가 나쁜 기업에는 결코 투자하지 않겠다</h3>
                  <p>래리핑크</p>
                  <span>세계 최대 규모 자산운용사 블랙록 최고 경영자</span>
                </div>
                <img src="/images/profile1.png" alt="래리핑크" />
              </HorizontalCard>
              <HorizontalCard>
                <div>
                  <h3>
                    2030년까지 전 코스피 상장사에 단계별 ESG 정보공시 의무화
                    추진 중
                  </h3>
                  <p>K-ESG 가이드라인 中</p>
                </div>
                <img src="/images/profile2.png" alt="K-ESG 가이드라인" />
              </HorizontalCard>
              <HorizontalCard>
                <div>
                  <h3>30대 대기업 87% “협력사 ESG 평가한다”</h3>
                  <p>중소기업중앙회</p>
                </div>
                <img src="/images/profile3.png" alt="중소기업중앙회" />
              </HorizontalCard>
            </LeftCardGroup>
            <RightCard>
              <h3>ESG 도입이 어려운가요?</h3>
              <img
                src="/images/esg-donut-grpah.png"
                alt="ESG 경영이 준비되어있지 않아
도입이 어렵다. 중소기업 300개 대상
중소기업 중앙회 ‘중소기업 ESG애로조사’"
              />
            </RightCard>
          </CardsContainer>
        </InnerWrapper>
      </EsgSection>
    </>
  );
};

export default Esg;
