import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const OptionsSection = styled.section`
  padding: 80px 0;
  background-color: #f9f9fb;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 1.4em;
  word-wrap: break-word;
  word-break: keep-all;
  text-align: center;
  ${mq[0]} {
    font-size: 40px;
  }

  br {
    ${mq[0]} {
      display: none;
    }
  }
`;

const ContsContainer = styled.div`
  margin-top: 46px;
  ${mq[0]} {
    margin-top: 85px;
  }
`;

const ContsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 56px;

  ${mq[0]} {
    flex-direction: row;
    gap: 30px;
  }

  &.reverse {
    ${mq[0]} {
      flex-direction: row-reverse;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ThumbnailBox = styled.div`
  width: 100%;
  ${mq[0]} {
    width: 40%;
  }
  img {
    width: 100%;
  }
`;

const TextBox = styled.div`
  width: 100%;
  word-wrap: break-word;
  word-break: keep-all;
  ${mq[0]} {
    width: 60%;
  }
  h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.6em;
    ${mq[0]} {
      font-size: 30px;
    }
  }
  p {
    margin-top: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5em;
    color: #52565c;
    ${mq[0]} {
      margin-top: 30px;
      font-size: 18px;
    }
  }
`;

const Aligner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  height: 100%;

  &.reverse {
    ${mq[0]} {
      margin-left: auto;
    }
  }
`;

const Options = () => {
  return (
    <>
      <OptionsSection>
        <InnerWrapper>
          <Title>
            이런 옵션도 있으니
            <br /> 확인해 보세요.
          </Title>
          <ContsContainer>
            <ContsGroup>
              <ThumbnailBox>
                <img src="/images/option1.png" alt="렌탈 & 구입" />
              </ThumbnailBox>
              <TextBox>
                <Aligner>
                  <h3>
                    1<br />
                    렌탈/구입 선택 가능
                  </h3>
                  <p>
                    펴다 스마트우산공유함(펴다 박스) 구매가 망설여지시나요? 이런
                    분들을 위해 펴다는 렌탈 서비스도 제공합니다. 부담 없이
                    렌탈로 우산 보관함을 도입해보고 연장/구매는 나중에
                    생각해보셔도 괜찮습니다.
                  </p>
                </Aligner>
              </TextBox>
            </ContsGroup>
            <ContsGroup className="reverse">
              <ThumbnailBox>
                <img src="/images/option2.png" alt="고객사 맞춤형 옥외광고" />
              </ThumbnailBox>
              <TextBox>
                <Aligner className="reverse">
                  <h3>
                    2<br />
                    고객사 맞춤형 옥외광고
                  </h3>
                  <p>
                    펴다 스마트우산공유함(펴다 박스)과 펴다 우산에 광고를 실어,
                    비 오는 날 특히 극대화되는 광고효과를 경험해보세요. 비가
                    오지 않는 날에도 펴다 박스 광고로 지속적인 광고가
                    가능합니다.
                  </p>
                </Aligner>
              </TextBox>
            </ContsGroup>
            <ContsGroup>
              <ThumbnailBox>
                <img src="/images/option3.png" alt="제휴포인트 연동" />
              </ThumbnailBox>
              <TextBox>
                <Aligner>
                  <h3>
                    3<br />
                    제휴포인트 연동
                  </h3>
                  <p>
                    자사의 포인트로 우산을 대여할 수 있도록 하여, 포인트 혜택을
                    강화하고, 덕분에 고객이 비가 오는 난처한 상황을 벗어나는
                    경험을 선물할 수 있습니다.
                  </p>
                </Aligner>
              </TextBox>
            </ContsGroup>
          </ContsContainer>
        </InnerWrapper>
      </OptionsSection>
    </>
  );
};

export default Options;
