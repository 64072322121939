import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Main from './pages/Main/Main'
import Adopt from './pages/Adopt/Adopt'
import { mq } from './theme/breakpoints'
import Reduction from './pages/Reduction/Reduction'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const HeaderArea = styled.div`
  height: 62px;

  ${mq[0]} {
    height: 82px;
  }
`

function App() {
  const location = window.location.pathname
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {location !== '/reduction' &&
          (
            <>
              <Header />
              <HeaderArea />
            </>
          )
        }
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/adopt" element={<Adopt />} />
          <Route path="/reduction" element={<Reduction />} />
        </Routes>
        {location !== '/reduction' &&
          <Footer />
        }
      </BrowserRouter>
    </>
  )
}

export default App
