import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const StepsSection = styled.section`
  padding: 80px 0;
  ${mq[0]} {
    padding: 120px 0;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
  max-width: 1320px;
  margin: 0 auto;
`;

const SubTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3em;
  text-align: center;
  color: #3c75f1;

  ${mq[0]} {
    font-size: 24px;
  }
`;

const Title = styled.h2`
  margin-top: 12px;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
  ${mq[0]} {
    margin-top: 20px;
    font-size: 84px;
  }
`;

const ContsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  ${mq[0]} {
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 20px;
    margin-top: 80px;
  }
`;

const StepGroup = styled.div`
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  &.center {
    align-self: flex-end;
  }
  ${mq[0]} {
    width: calc(33.3% - 20px * 2 / 3);
    max-width: unset;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
  ${mq[0]} {
    display: block;
  }

  img {
    width: 100%;
    border-radius: 13px;
  }

  div {
    margin-top: 25px;
    ${mq[0]} {
      margin-top: 46px;
    }
  }
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: #3c75f1;
  border-radius: 50%;

  span {
    font-size: 16px;
    color: #ffffff;
  }
`;

const Desc = styled.p`
  margin-top: 18px;
  font-weight: 700;
  font-size: 20px;
  word-break: keep-all;
  word-wrap: break-word;
  ${mq[0]} {
    margin-top: 10px;
    font-size: 24px;
  }
`;

const Arrow = styled.div`
  display: none;
  ${mq[0]} {
    display: block;
  }

  &.down {
    width: 70%;
    margin-left: auto;
    margin-top: 50px;
  }

  &.up {
    width: 60%;
    margin-top: 50px;
  }

  img {
    width: 100%;
  }
`;

const Steps = () => {
  return (
    <>
      <StepsSection>
        <InnerWrapper>
          <SubTitle>쉬운 사용을 위한 펴다 서비스</SubTitle>
          <Title>우산 빌리기 어렵지 않아요</Title>
          <ContsWrapper>
            <StepGroup>
              <Step>
                <img src="/images/effect-bg.jpg" alt="" />
                <div>
                  <Number>
                    <span>1</span>
                  </Number>
                  <Desc>스마트우산공유함 펴다 박스를 찾고</Desc>
                </div>
              </Step>

              <Arrow className="down">
                <img src="/images/arrow-down.png" alt="" />
              </Arrow>
            </StepGroup>
            <StepGroup className="center">
              <Step>
                <img src="/images/step2-bg.jpg" alt="" />
                <div>
                  <Number>
                    <span>2</span>
                  </Number>
                  <Desc>앱에서 대여하면</Desc>
                </div>
              </Step>
            </StepGroup>
            <StepGroup>
              <Step>
                <img src="/images/step3-bg.jpg" alt="" />
                <div>
                  <Number>
                    <span>3</span>
                  </Number>
                  <Desc>오늘도 환경을 지켜요!</Desc>
                </div>
              </Step>
              <Arrow className="up">
                <img src="/images/arrow-up.png" alt="" />
              </Arrow>
            </StepGroup>
          </ContsWrapper>
        </InnerWrapper>
      </StepsSection>
    </>
  );
};

export default Steps;
