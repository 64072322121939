import styled from 'styled-components';
import { mq } from '../../theme/breakpoints';

const FooterContainer = styled.div`
  background: #ffffff;
  width: 100%;
`;

const InnerWrapper = styled.div`
  padding: 20px 30px;
  max-width: 1320px;
  margin: 0 auto;
  ${mq[0]} {
    padding: 56px 60px;
  }
`;

const Logo = styled.a`
  display: block;
  height: 23px;
  margin-bottom: 28px;

  ${mq[0]} {
    height: 32px;
  }

  img {
    height: 100%;
  }
`;

const MainInfo = styled.strong`
  display: inline-block;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  color: #595959;
`;

const SubInfos = styled.ul`
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  color: #8c8c8c;

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Copy = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #bfbfbf;
`;

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <InnerWrapper>
          <Logo href="/">
            <img src="/images/logo.png" alt="펴다" />
          </Logo>
          <MainInfo>주식회사 펴다 ㅣ대표 : 김형석</MainInfo>
          <SubInfos>
            <li>본사 : 서울시 종로구 광화문 스타트업 빌리지</li>
            <li>사업자 등록 번호 : 710-87-02407</li>
            <li>지사 : 경기도 안산시 청년창업사관학교(안산)</li>
            <li>이메일 : support@pyeoda.co.kr</li>
          </SubInfos>
          <Copy>Copyright©2022 펴다All rights reserved</Copy>
        </InnerWrapper>
      </FooterContainer>
    </>
  );
};

export default Footer;
